import React from "react";


const GiftCardBanner = () => {
	return (
		<div className="gift-card-section" style={{ color: "white", backgroundColor: "#945341", borderRadius: 15, border : "1 px solid grey"}}>
			<p>Pour offrir nos ateliers, pensez aux <a className="has-text-terracota link" style={{color: "#e7ccb7"}} href="/ateliers/test-carte-cadeau">cartes cadeaux ! </a></p>
		</div>
	)
}

export default GiftCardBanner