import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, StaticQuery } from "gatsby";
import { getProduct } from "../../services/products";
import paragraphs from "lines-to-paragraphs";


import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageSlider from "../image/ImageSlider"; // requires a loader

const AtelierCard = ({ product }) => {

  const [placeLeft, setPlaceLeft] = useState(product.variants[0]?.inventoryQuantity)


  const [slides, setSlides] = useState([])

  const imageStyle = {borderRadius: '0px', margin: 'auto', height : "100%", maxHeight:"350px",  objectFit: "contain", justifyContent: "center", alignItems: "center", alignSelf: "center"   }


  const getProductQuantity = async () => {
    await getProduct(product.shopifyId).then((data) => {
      setPlaceLeft(data.data.product.variants[0].inventory_quantity)
    })
  }

  React.useEffect(() => {
    getProductQuantity()
    let images = [];
    product.media.map((img) => {
      if(img.preview.image.originalSrc) {
        images.push({title : product.title, image : img.preview.image.originalSrc, gatsbyImage: img.preview.image.gatsbyImageData, description : img.alt})
      }
    })
    setSlides(images)
  }, [])


  return (
    <div className="is-parent column is-12" key={product.id}>
      <article
        className={`tile is-child box`}
        style={{  borderRadius : 0}}
      >
        <div className="columns">
          <div className="column is-4"  style={{justifyContent : "center", alignItems: "center", display : "relative"}}>
            <ImageSlider slides={slides} imageStyle={imageStyle} showDescription={true} autoPlay={false}/>
          </div>

          <div className="column is-6 is-flex-direction-column is-flex is-justify-content-space-between">
            <div>
              <Link
                className="title has-text-primary is-size-5"
                to={product.handle}>
                <h3>
                  {product.title}
                </h3>
              </Link>
              <div>
                {!product.tags.includes("externe") &&
                <>
                  {placeLeft > 0 ?
                    <h5>{placeLeft} place(s) restante(s)</h5> :
                    <h5>COMPLET</h5>
                  }
                </>
                }

                <div dangerouslySetInnerHTML={{ __html: paragraphs(product.descriptionHtml) }} style={{ marginTop: 10 }} />

              </div>
            </div>

            <div className="field">

              <Link className="button" to={"/ateliers/" + product.handle} style={{ marginTop: 10, width : "100px"}}>
                {placeLeft > 0 ? "Réserver" : "Voir"}
              </Link>
            </div>
          </div>
        </div>
      </article>
    </div>
  )
}

export default AtelierCard
