import axios from "axios";

export async function getProducts() {
  const url = process.env.BACKEND_API_URL + '/products'
  try {
    const dataCall = await axios.get(url, {
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer e9e9a5bb-50de-47a2-94e3-4ee620d1372c`,
      },
    });

    return dataCall
  } catch (e) {
    console.error("Error while fetching product data :", e)
  }
}

export async function getProduct(productId) {
  const productIdFormatted = productId.slice(22)

  const url = process.env.BACKEND_API_URL + '/product/' + productIdFormatted

  try {
    const dataCall = await axios.get(url, {
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer e9e9a5bb-50de-47a2-94e3-4ee620d1372c`,
      },
    });

    return dataCall
  } catch (e) {
    console.error("Error while fetching product data :", e)
  }
}


