import React, { useEffect, useState } from "react";
import { graphql, Link, navigate } from "gatsby";

import Layout from "../../components/layout/Layout"
import AtelierCard from "../../components/atelier/AtelierCard"
import GiftCardBanner from "../../components/gift-card/GiftCardBanner";

const Products = ({ location, data }) => {
	const { nodes } = data.allShopifyProduct


	useEffect(() =>{
	},[])

	const [collectionTitle, setCollectionTitle] = useState("");

	const [atLeastOnetelierAvailable, setAtLeastOnetelierAvailable] = useState(false)

	// console.log("nodes : ", nodes)

	useEffect(() => {
		if(!location) {
			setCollectionTitle(JSON.parse(window.sessionStorage.getItem("collectionTitle")));
		}
		if(location.state && location.state.title) {
			{
				nodes?.map((product, index) => {
					product.collections.map((object) => {
						if (object.title === location.state.title) {
							setAtLeastOnetelierAvailable(true)
						}
					})
				})
			}
		} else {
			navigate('/ateliers/');
		}

	}, []);

	useEffect(() => {
		if(location.state && location.state.title) {
			setCollectionTitle(location.state.title)
			window.sessionStorage.setItem("collectionTitle", location.state.title);
		}
	}, []);

	return (
		<Layout>
			<section className="section">
				<div className="container">
					{(location && location.state && location.state.title) &&
					<div className="content is-offset-1" style={{marginTop: '2rem'}}>
						<p></p>
						<div>
								<h1 className="has-text-weight-bold has-text-centered is-size-1">
									{location.state.title} :</h1>
						</div>


						<GiftCardBanner/>


						<div className="columns is-multiline" style={{marginTop: "1rem"}}>
							{nodes?.slice(0).reverse().map((product, index) => {
								return (
									// Map on product :
									product.collections.map((object) => {
										// And display the product only if it's the good collection (collection name is stored in location.state.title)
										if(object.title.includes(location.state.title)) {
											// Opject is the collection of the product here
											if(product.status === "ACTIVE") {
												return (
													<AtelierCard key={index} product={product} />
												)
											}
										}
									}))
							})}

							{!atLeastOnetelierAvailable &&
							<div className="has-text-centered mt-2" style={{textAlign: "center", marginTop : "2rem"}} >
								<p>Aucun atelier n'est programmé pour le moment, revenez plus tard !!</p>
							</div>
							}

						</div>
					</div>
					}
				</div>
			</section>
		</Layout>)
}

export default Products

export const query = graphql`
	{
		allShopifyProduct
		  {
			nodes {
				title
				handle
				shopifyId
				createdAt
				variants {
        			shopifyId
        			inventoryQuantity
      			}
				priceRangeV2 {
					maxVariantPrice {
						amount
					}
				}
        collections {
          handle
          title
        }
				media {
					id
					alt
					status
					preview {
						image {
							originalSrc
							transformedSrc
							altText
							gatsbyImageData
							height
							src
							width
						}
					}
				}
        status
				description
				descriptionHtml
				tags
				featuredImage {
          src 
          height
          originalSrc
          width
          altText
          gatsbyImageData
        }
			}
		}
	}
`
